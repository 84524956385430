import React, { useState, useEffect, useCallback } from 'react';
import { Avatar, Dropdown, Navbar } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout } from 'app/slices/auth';
import './style.scss';
import SidebarMenu from 'app/components/SidebarMenu';
import { NavLink, useLocation } from 'react-router-dom';
import { shouldHideSidebar } from 'app/helpers/utils';
import { getPlatforms } from '@ionic/core';
import SidebarMenuMobile from 'app/components/SidebarMenuMobile';
import { Capacitor } from '@capacitor/core';
import { useMediaQuery } from '@mui/material';

const ProfileHeader = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const profileData = useSelector(state => state.user.profile);
	const { isLoggedIn } = useSelector(state => state.auth);
	const [initial, setInitial] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		if (profileData) {
			setInitial(profileData.first_name.charAt(0) + profileData.last_name.charAt(0));
		}
	}, [profileData]);

	// Sidebar state
	const [sideBar, setSideBar] = useState(false);

	// Memoize toggleSidebar with useCallback to avoid re-creating the function
	const toggleSidebar = useCallback((event) => {
		if (event) event.stopPropagation();
		setSideBar(prevState => !prevState);
	}, []);



	const [state, setState] = useState({
		left: false
	});

	// Platform check for mobile rendering adjustments
	const isMobile = useMediaQuery('(max-width:968px)') || Capacitor.isNativePlatform();

	return (
		<>
			<nav className={`${isMobile ? '' : 'fixed'} top-0 z-50 w-full border-b bg-gray-900 border-gray-900`}>
				<div className="px-3 py-3 lg:px-5 lg:pl-3">
					<div className="flex items-center justify-between">
						<div className="flex items-center justify-start rtl:justify-end">
							{isLoggedIn &&
								(isMobile ?
									<>
										<button
											onClick={(e) => toggleSidebar(e)} // Pass the event to stop propagation
											aria-controls="logo-sidebar"
											type="button"
											className="inline-flex items-center p-2 text-sm rounded-lg focus:outline-none focus:ring-2 text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
										>
											<span className="sr-only">Open sidebar</span>
											<svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
												<path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
											</svg>
										</button>
									</> :
									<>
										<button
											onClick={toggleSidebar}
											data-drawer-target="logo-sidebar"
											data-drawer-toggle="logo-sidebar"
											aria-controls="logo-sidebar"
											type="button"
											className="inline-flex items-center p-2 text-sm rounded-lg focus:outline-none focus:ring-2 text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
										>
											<span className="sr-only">Open sidebar</span>
											<svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
												<path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
											</svg>
										</button>
									</>
								)
							}

						</div>
						<NavLink to="/" className="flex items-center mx-auto">
							<span
								style={{
									marginLeft: isLoggedIn && !isMobile ? "120px" : "0px",
								}}
								className="self-center text-xl sm:text-2xl whitespace-nowrap text-slate-300">The <span className='font-semibold text-white'>Hush</span> Market</span>
						</NavLink>
						{isLoggedIn && (
							<div className="flex items-center">
								<div className="flex items-center ms-3">
									<div className="flex md:order-2">
										<Dropdown
											arrowIcon={false}
											inline
											label={
												<div className="relative bg-white text-black rounded-full flex justify-center items-center text-center p-2 shadow-xl">
													{initial}
												</div>
											}
										>
											<Dropdown.Header>
												<span className="block text-sm">{profileData?.firstname} {profileData?.lastname}</span>
												<span className="block truncate text-sm font-bold">{profileData?.email}</span>
											</Dropdown.Header>
											<Dropdown.Item href='/profile'>Mon Compte</Dropdown.Item>
											<Dropdown.Item href='/profile?sub=agence'>Mon Agence</Dropdown.Item>
											<Dropdown.Item href='/profile?sub=security'>Sécurité</Dropdown.Item>
											{profileData?.isOwner && <Dropdown.Item href='/profile?sub=staff'>Mon Équipe</Dropdown.Item>}
											<Dropdown.Divider />
											<Dropdown.Item onClick={() => {
												dispatch(logout());
												window.location.href = '/';
												window.scrollTo(0, 0);
											}}>Sign out</Dropdown.Item>
										</Dropdown>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</nav>

			{/* Render SidebarMenu component */}
			{isLoggedIn && !shouldHideSidebar(pathname) &&
				(
					isMobile ? <SidebarMenuMobile sideBar={sideBar} toggleSidebar={toggleSidebar} /> : <SidebarMenu sideBar={sideBar} toggleSidebar={toggleSidebar} />

				)
			}
		</>
	);
};

export default ProfileHeader;
