import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const SmartBar = () => {
    const [showBar, setShowBar] = useState(false);

    useEffect(() => {
        const dismissed = localStorage.getItem('smartBarDismissed');
        if (isMobile && !dismissed) {
            setShowBar(true);
        }
    }, []);

    const handleDismiss = () => {
        setShowBar(false);
        localStorage.setItem('smartBarDismissed', 'true');
    };

    const openAppOrRedirect = () => {
        const deepLinkURL = "applink:https://pro.thehushmarket.com/login";
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let storeURL = '';

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            storeURL = 'https://apps.apple.com/fr/app/the-hush-market/id6736993085';
        } else if (/android/i.test(userAgent)) {
            storeURL = 'https://play.google.com/store/apps/details?id=pro.thehushmarket.app';
        } else {
            storeURL = 'https://your-app-download-link.com';
        }

        const now = Date.now();
        window.location.href = storeURL;
        setTimeout(() => {
            if (Date.now() - now < 1500) {
                window.location.href = storeURL;
            }
        }, 1500);
    };

    if (!showBar) return null;

    return (
        <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
            <div className="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
                <p className="text-sm text-gray-900">
                    Pour une meilleure expérience, téléchargez notre application !
                </p>
                <div className="mt-4 flex items-center gap-x-5">
                    <button
                        onClick={openAppOrRedirect}
                        type="button"
                        className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                        Ouvrir / Télécharger l'app
                    </button>
                    <button
                        onClick={handleDismiss}
                        type="button"
                        className="text-sm font-semibold text-gray-900"
                    >
                        Fermer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SmartBar;
