import { useEffect, useCallback, useContext, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProfileHeader from 'app/components/common/Profile-Header/ProfileHeader.js';

import { logout } from 'app/slices/auth';
import { clearMessage } from 'app/slices/message';

import { history } from 'app/helpers/history';
import EventBus from 'app/common/EventBus';
import AppRouter from 'app/router';
import AuthVerify from 'app/common/AuthVerify.js';
import RestoreLogin from 'app/common/RestoreLogin';
import Snackbar from 'app/snackbar';
import SnackbarContext from 'app/snackbar/snackbar-context';
import { CircularProgress, Box, useMediaQuery } from '@mui/material';
import Footer from './components/common/Footer/Footer';
import { PusherProvider } from './PusherContext'; // Import the PusherProvider
import FetchUnreadMessage from './common/FetchUnreadMessage';
import InscriptionVerify from './common/InscriptionVerify';
import InvoiceVerify from './common/InvoiceVerify';
import { getPlatforms } from '@ionic/core';
import OneSignal from 'onesignal-cordova-plugin';
import AppUrlListener from './AppUrlListener';
import { Capacitor } from '@capacitor/core';
import SmartBar from './SmartBar';
import ProductTour from './components/ProductTour';

const App = () => {
	const dispatch = useDispatch();
	const { i18n } = useTranslation();
	const { user: currentUser } = useSelector(state => state.auth);
	const profileData = useSelector(state => state.user.profile);

	const snackbarCtx = useContext(SnackbarContext);

	// Platform check for mobile rendering adjustments
	const isMobile = useMediaQuery('(max-width:968px)');

	/**
	 * CAPACITOR ONESIGNAL
	 */
	const OneSignalInit = () => {
		OneSignal.initialize('04e57e38-002b-4f63-b0fc-e975d8b3430e');
		OneSignal.Notifications.addEventListener('click', async (e) => {
			let clickData = await e.notification;
			console.log("Notification Clicked : " + clickData);
		})
		OneSignal.Notifications.requestPermission(true).then((success) => {
			console.log("Notification permission granted " + success);
		})
	};

	if (Capacitor.isNativePlatform()) {
		OneSignalInit();
	}
	/**
	 * END CAPACITOR
	 */

	useEffect(() => {
		history.listen(location => {
			dispatch(clearMessage()); // clear message when changing location
		});
	}, [dispatch]);

	const logOut = useCallback(() => {
		dispatch(logout());
	}, [dispatch]);

	useEffect(() => {
		EventBus.on('logout', () => {
			logOut();
		});

		return () => {
			EventBus.remove('logout');
		};
	}, [currentUser, logOut]);

	useEffect(() => {
		if (profileData?.defautLang?.codeLang !== undefined) i18n.changeLanguage(profileData?.defautLang?.codeLang);
		// eslint-disable-next-line
	}, [profileData]);


	return (
		<PusherProvider>
			<div className="flex flex-col h-screen justify-between">
				{snackbarCtx.isDisplayed && <Snackbar />}
				<RestoreLogin />
				<SmartBar />
				<FetchUnreadMessage />
				<BrowserRouter history={history} >
					<InscriptionVerify />
					<InvoiceVerify />
					<AuthVerify logOut={logOut} />
					<ProfileHeader />

					<Suspense
						fallback={
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: '100%',
									height: 'calc(100vh - 98px)',
									color: 'white'
								}}
							>
								<CircularProgress color="inherit" />
							</Box>
						}
					>
						{/* {subscriptionStatus?.latest_payment_status == "requires_payment_method" &&
							<>
								<aside class="fixed z-50 flex items-center justify-center px-5 py-3 rounded-lg bottom-2 right-2">
									<div class="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
										<svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
											<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
										</svg>
										<span class="sr-only">Payment en attente</span>
										<div>
											<span class="font-medium">Oups..</span> Merci de regler votre facture : <a href={subscriptionStatus.hosted_invoice_url} className="hover:text-indigo-600">ici</a>
										</div>
									</div>
								</aside>
							</>
						} */}
						<AppRouter></AppRouter>
					</Suspense>
					{!isMobile && <Footer />}
					{profileData && <ProductTour />}
					<AppUrlListener />
				</BrowserRouter>
			</div>
		</PusherProvider>
	);
};

export default App;
