import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleAppUrlOpen = (event: URLOpenListenerEvent) => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split('pro.thehushmarket.com').pop();
            if (slug) {
                navigate(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        };

        App.addListener('appUrlOpen', handleAppUrlOpen);

        return () => {
            App.removeListener('appUrlOpen', handleAppUrlOpen);
        };
    }, [navigate]);

    return null;
};

export default AppUrlListener;
