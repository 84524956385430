import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const ProductTour = () => {
    const [run, setRun] = useState(false);
    const [steps] = useState([
        {
            target: '[data-tour="mes-matchs"]',
            content: (
                <div className="p-4">
                    <h3 className="text-lg font-bold mb-2">Mes Matchs 🚀</h3>
                    <p className="mb-2">Cette page te permet de visualiser en temps réel les correspondances entre les acquéreurs et les biens. Tu y vois les matchs générés par le système, qui associe les projets d'acquéreurs aux biens off market inscrits.</p>
                    <p className="text-sm font-semibold">Objectif : Suivez vos correspondances pour booster vos ventes 🚀 #Opportunités</p>
                </div>
            ),
            placement: 'right',
            disableBeacon: true,
        },
        {
            target: '[data-tour="mes-acquereurs"]',
            content: (
                <div className="p-4">
                    <h3 className="text-lg font-bold mb-2">Mes Acquéreurs</h3>
                    <p className="mb-2">Ici, tu gères l'ensemble de tes clients ainsi que leurs projets (recherche client). La page centralise toutes les informations relatives aux acquéreurs et aux projets associés, te permettant de suivre leur intérêt, de qualifier leurs demandes et de gérer efficacement les interactions.</p>
                    <p className="text-sm font-semibold">Objectif : Centralisez vos clients et projets pour mieux convertir 💼 #GestionClient</p>
                </div>
            ),
            placement: 'right',
        },
        {
            target: '[data-tour="mes-biens"]',
            content: (
                <div className="p-4">
                    <h3 className="text-lg font-bold mb-2">Mes Biens Off Market</h3>
                    <p className="mb-2">Cette section est dédiée à la gestion de tes biens off market en mandat. Tu peux publier, mettre à jour et suivre tes annonces, tout en garantissant la confidentialité de tes données.</p>
                    <p className="text-sm font-semibold">Objectif : Le coeur du match 🔥 #BiensOffMarket</p>
                </div>
            ),
            placement: 'right',
        },
        {
            target: '[data-tour="recherche"]',
            content: (
                <div className="p-4">
                    <h3 className="text-lg font-bold mb-2">Recherche</h3>
                    <p className="mb-2">Sur cette page, tu peux effectuer une recherche rapide dans l'ensemble des biens présents sur la plateforme. Elle te permet également d'envoyer directement des demandes d'information si un bien t'intéresse.</p>
                    <p className="text-sm font-semibold">Objectif : Accédez rapidement à l'info pour des décisions éclairées 🔍 #SmartSearch</p>
                </div>
            ),
            placement: 'right',
        },
        {
            target: '[data-tour="synthese"]',
            content: (
                <div className="p-4">
                    <h3 className="text-lg font-bold mb-2">Synthèse</h3>
                    <p className="mb-2">Cette page centralise toutes les demandes d'information et les correspondances (matchs). Elle te donne une vision globale de ton activité et t'aide à mesurer l'impact de tes actions sur la plateforme.</p>
                    <p className="text-sm font-semibold">Objectif : Vue globale pour optimiser votre stratégie 📊 #Performance</p>
                </div>
            ),
            placement: 'top',
        },
        {
            target: '[data-tour="messagerie"]',
            content: (
                <div className="p-4">
                    <h3 className="text-lg font-bold mb-2">Messagerie</h3>
                    <p className="mb-2">Elle te permet d'échanger directement avec les autres membres de la plateforme dès qu'un match est fait ou qu'une demande d'information est émise. C'est 100% anonyme et confidentiel.</p>
                    <p className="text-sm font-semibold">Objectif : Concrétiser les opportunités 💬 #Collaboration</p>
                </div>
            ),
            placement: 'top',
        },
        {
            target: '[data-tour="faq"]',
            content: (
                <div className="p-4">
                    <h3 className="text-lg font-bold mb-2">FAQ & Aide</h3>
                    <p className="mb-2">Retrouvez ici toutes les réponses à vos questions sur l'utilisation de la plateforme. Notre FAQ complète vous guide à travers les fonctionnalités et les bonnes pratiques pour tirer le meilleur parti de T.H.M.</p>
                    <p className="text-sm font-semibold">Objectif : Trouvez rapidement des réponses à vos questions ❓ #Support</p>
                </div>
            ),
            placement: 'top',
        },
    ]);

    useEffect(() => {
        // Check if this is the first visit
        const hasSeenTour = localStorage.getItem('hasSeenTour');
        if (!hasSeenTour) {
            setRun(true);
            localStorage.setItem('hasSeenTour', 'true');
        }
    }, []);

    const handleJoyrideCallback = (data) => {
        const { status } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRun(false);
        }
    };

    return (
        <>
            <div className="fixed bottom-4 right-4" style={{ zIndex: 9999 }}>
                <div className="relative group">
                    <button
                        onClick={() => setRun(true)}
                        className="flex items-center justify-center w-12 h-12 bg-blue-600 rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-200"
                        title="Découvrir les fonctionnalités de T.H.M"
                    >
                        <QuestionMarkCircleIcon className="w-6 h-6 text-white" />
                    </button>
                    <div className="absolute bottom-full right-0 mb-2 w-64 transform translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                        <div className="bg-gray-900 text-white text-sm p-3 rounded-lg shadow-xl relative">
                            Besoin d'aide ? Découvrez toutes les fonctionnalités de T.H.M en suivant notre guide interactif 🚀
                            <div className="absolute -bottom-2 right-5 w-4 h-4 bg-gray-900 transform rotate-45"></div>
                        </div>
                    </div>
                </div>
            </div>

            <Joyride
                callback={handleJoyrideCallback}
                continuous
                hideCloseButton
                run={run}
                scrollToFirstStep
                disableScrollParentFix
                disableScrolling
                showSkipButton
                steps={steps}
                locale={{
                    last: "Terminer",
                    back: "Retour",
                    next: "Suivant",
                    skip: "Passer"
                }}
                styles={{
                    options: {
                        arrowColor: '#1f2937',
                        backgroundColor: '#1f2937',
                        overlayColor: 'rgba(0, 0, 0, 0.5)',
                        primaryColor: '#3b82f6',
                        textColor: '#ffffff',
                        zIndex: 1000,
                        tooltipContainer: {
                            position: 'fixed',
                            zIndex: 1000
                        },
                        tooltip: {
                            position: 'fixed',
                            zIndex: 1000
                        },
                        spotlight: {
                            position: 'fixed',
                            zIndex: 1000
                        },
                        overlay: {
                            position: 'fixed',
                            zIndex: 1000
                        }
                    },
                }}
            />
        </>
    );
};

export default ProductTour; 