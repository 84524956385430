import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import parseJwt from './parseJwt';
import { useSelector } from 'react-redux';
import FullScreenLoading from 'app/components/common/FullScreenLoading';

const InscriptionVerify = props => {
	const location = useLocation();
	const profileData = useSelector(state => state.user.profile);
	const { isLoggedIn } = useSelector(state => state.auth);
	useEffect(() => {
		if (isLoggedIn && profileData) {
			if (!profileData.is_inscription_done) {
				switch (profileData.inscription_step) {
					case "agency":
						if (location.pathname !== '/create/step3') {
							window.location.href = '/create/step3';
						}
						break;
					// case "select-plan":
					// 	if (location.pathname !== '/create/step4') {
					// 		window.location.href = '/create/step4';
					// 	}
					// 	break;
				}

			}
		}
		// eslint-disable-next-line
	}, [location, profileData]);

	return <div></div>;
};

export default InscriptionVerify;
