import axios, { switchApiBaseURL } from 'axiosInstance.js';

import { getPlatforms } from '@ionic/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';

export const authRegister = async payload => {
	return axios.post('auth/register', payload);
};

export const authLogin = async payload => {
	// Check if the email matches for demo environment
	if (payload.email === 'demo@thehushmarket.com') {
		// If it's the demo user, switch to demo API URL
		switchApiBaseURL('DEMO');
	} else {
		// If it's a regular user, switch to prod API URL
		switchApiBaseURL('PROD');
	}
	return axios.post('auth/login', payload).then(response => {
		if (response.status && response.data.token) {
			localStorage.setItem('user', JSON.stringify(response.data));

			if (Capacitor.isNativePlatform()) {
				SavePassword.promptDialog({
					username: payload.email,
					password: payload.password
				}).then(() => console.log('promptDialog success'))
					.catch((err) => console.error('promptDialog failure', err));

				let externalUserId = response.data.id.toString();

				// Setting External User Id with Callback Available in SDK Version 2.11.2+
				OneSignal.login(externalUserId, (results) => {
					// The results will contain push and email success statuses
					console.log('Results of setting external user id');
					console.log(results);
				});

			}
		}

		return response;
	});
};

export const authRefresh = async payload => {
	return axios.post('token/refresh', payload).then(response => {
		if (response) {
			localStorage.setItem('user', JSON.stringify(response));
		}

		return response;
	});
};

export const authLogout = () => {
	localStorage.removeItem('user');
	localStorage.removeItem('modalShown');
	localStorage.removeItem('baseURL');
	localStorage.removeItem('broadcastingURL');
	localStorage.removeItem('lastModalShown');
};

export const reset = async payload => {
	return axios.post('reset-password', payload);
};

export const verifyToken = async payload => {
	return axios.post('reset-password/verify-reset-token', payload);
};

export const saveNewPassword = async payload => {
	return axios.post('reset-password/save-new-password', payload);
};

export const verifyEmail = async (token) => {
	return axios.get(`email/verify/${token}`);
};
