import axios from 'axios';

// Default API URL
const API_URL = localStorage.getItem("baseURL") || process.env.REACT_APP_API_URL;

// Create the axios instance with default API URL
const instance = axios.create({
	baseURL: API_URL,
	paramsSerializer: params => params
});

// Utility function to switch API URL dynamically based on the environment (e.g., 'DEMO' or 'PROD')
export const switchApiBaseURL = (env) => {
	let newBaseURL = process.env.REACT_APP_API_URL;  // Default to production API URL
	let newBroadcastingURL = process.env.REACT_APP_API_BROADCASTING_URL; // Default to production broadcasting URL

	if (env === "DEMO") {
		newBaseURL = process.env.REACT_APP_DEMO_API_URL;  // Switch to demo API URL
		newBroadcastingURL = process.env.REACT_APP_DEMO_API_BROADCASTING_URL; // Switch to demo broadcasting URL
	}

	// Dynamically update the baseURL for the axios instance
	instance.defaults.baseURL = newBaseURL;
	localStorage.setItem("broadcastingURL", newBroadcastingURL);
	localStorage.setItem("baseURL", newBaseURL);
};

// Axios request interceptor to add authorization headers
instance.interceptors.request.use((config) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const token = user?.token ?? '';  // Extract the token from localStorage
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;  // Set the Authorization header if token exists
	}
	return config;
}, (error) => {
	return Promise.reject(error);  // Handle request errors
});

// Axios response interceptor to handle global responses and errors
instance.interceptors.response.use(
	(response) => {
		if (response?.data?.message === 'Expired JWT Token' || response?.data?.message === 'Invalid access') {
			localStorage.removeItem('user');
			// Use window.location.replace to avoid adding to browser history
			window.location.replace('/login?expired');
		}
		return response.data;  // Return the successful response data
	},
	(error) => {
		if (error.response?.status === 401) {
			localStorage.removeItem('user');
			// Use window.location.replace to avoid adding to browser history
			window.location.replace('/login?expired');
		}
		return Promise.reject(error);  // Propagate the error
	}
);

export default instance;
